
<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <!-- <div class="model-content">
      <div class="modal-card"> -->
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content"></slot> -->
      <div>
        <image-select v-if="innerEmailer && isActiveImageSelect"
          v-model="imagesSelectedForAttachment"
          :quote-id="innerEmailer.assetId"
          :quote-ids="attachmentOptions.selectedQuotes.map(q => q.value)"
          :subtitle="`Select images to attach`"
          @input="getSelectedImage" />
        <quote-email-attachments v-if="isActiveAttachment"
          :quote-id="innerEmailer.assetId"
          :email-id="innerSelectedAttachmentEmailId"
          :is-incoming="isIncoming"
          @close="closeAttachmentsModel"
        />
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        v-show="isActiveImageSelect"
        @click="ok()"
        data-tooltip="Done"
        :disabled="!canAttach">
        <span>Ok</span>
      </button>
      <!-- <button class="button is-primary tooltip"
        v-show="!attachDisabled"
        @click="attach()"
        :data-tooltip="[!toggleAttachButton ? 'Attach images' : 'Done']"
        :disabled="attachDisabled">
        <span v-if="!toggleAttachButton"
          class="icon is-small">
          <i class="mdi mdi-18px mdi-paperclip" />
        </span>
        <span v-else>Done</span>
      </button> -->
      <button class="button tooltip"
        @click="cancel()"
        :data-tooltip="cancelInWording"
        :disabled="cancelDisabled">{{ cancelInWording }}</button>
    </footer>
    <!-- </div>
    </div> -->
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { mapGetters } from 'vuex'
import ImageSelect from '@/components/ImageSelect'
import Guid from '@/components/Guid'
// import { Emailer } from '@/classes'
// import StoreUtil from '@/store/utils'
// import PrintPreviewRoutes from '@/components/printpreview/route-types'
// import { RemarkTypes, AssetTypes } from '@/enums'
// import { KeyValuePairModel } from '@/classes/viewmodels'
import QuoteCommunicationsMixin from '../mixins/QuoteCommunicationsMixin'
import QuoteEmailAttachments from './QuoteEmailAttachments'

export default {
  name: 'QuoteCommunicationEmailModal',
  components: {
    BaseModalEx,
    ImageSelect,
    QuoteEmailAttachments
  },
  mixins: [QuoteCommunicationsMixin],
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-attachment'
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    attachDisabled: {
      type: Boolean,
      default: true
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    },
    emailer: {
      type: Object,
      defaul: {}
    },
    // toggleAttachButton: {
    //   type: Boolean,
    //   default: false
    // }
    selectedAttachmentEmailId: {
      type: String,
      default: ''
    },
    isIncoming: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      innerValue: null,
      imagesSelectedForAttachment: [],
      attachmentOptions: {
          selectedQuotes: []
      },
      toggleAttachButton: false,
      innerEmailer: {},
      isActiveImageSelect: true,
      isActiveAttachment: false
    }
  },
  computed: {
    ...mapGetters(['licenceExpired']),
    childModel: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    canAttach() {
      return this.imagesSelectedForAttachment.length > 0
    },
    cancelInWording() {
      return (this.isActiveAttachment) ? 'Close' : 'Cancel'
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    value: {
      handler: function(newVal, oldVal) {
        //console.log(newVal)
        this.imagesSelectedForAttachment = newVal
      }
    },
    selectedAttachmentEmailId: {
      handler: function(newVal, oldVal) {
        this.innerSelectedAttachmentEmailId = newVal
        this.isActiveAttachment = !!(newVal)
        this.isActiveImageSelect = !this.isActiveAttachment
      }
    }
  },
  created() {
    this.imagesSelectedForAttachment = this.value
    this.innerEmailer = this.emailer
    //console.log(this.innerEmailer)
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    // ...mapActions('printpreview', { addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    // ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' }),
    ok() {
      //this.isActive = false
      //this.$emit('update:active', false)
      //this.imagesSelectedForAttachment = this.childModel
      this.childModel = this.imagesSelectedForAttachment
      this.setEmailerAttachments({ emailerId: this.innerEmailer.id, attachments: this.imagesSelectedForAttachment })
      this.reset()
      this.$emit('ok')
      //this.print()
    },
    // attach() {
    //   this.toggleAttachButton = !this.toggleAttachButton
    //   // const param = {
    //   //   quoteId: this.innerEmailer.assetId,
    //   //   images: this.imagesSelectedForAttachment
    //   // }
    //   // this.imagesSelectedForAttachment.forEach(i => { i.text = i.fileName })
    //   this.setEmailerAttachments({ emailerId: this.innerEmailer.id, attachments: this.imagesSelectedForAttachment })
    //   this.childModel = this.imagesSelectedForAttachment
    // },
    cancel() {
      //this.imagesSelectedForAttachment.splice(0, this.imagesSelectedForAttachment.length)
      //this.isActive = false
      //this.$emit('update:active', false)
      this.reset()
      this.$emit('cancel')
    },
    reset() {
      this.toggleAttachButton = false
    },
    getImageIdForAttachment(index) {
      if (index <= this.imagesSelectedForAttachment.length) {
        return this.imagesSelectedForAttachment[index - 1].quoteImageId
      } else {
        return Guid.empty()
      }
    },
    getSelectedImage(selectedImages) {
      this.imagesSelectedForAttachment = selectedImages
    },
    updateAttachment(){
      this.$emit('updateAttachment')
    },
    closeAttachmentsModel() {
      this.isActiveAttachment = false
      this.isActiveImageSelect = true
      this.isActive = false
      this.innerSelectedAttachmentEmailId = ''
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
