<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem;">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Attachment Viewer</span>
      </p>
    </header>
    <section class="modal-card-body p-0 m-0"
      style="position:relative;">
      <div class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="box p-0 m-0">
            <figure class="image">
              <LazyImage class="image"
                :src="mainImageLink" />
            </figure>
          </div>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">

      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;">
          <button class="button tooltip"
            @click="()=>{downloadModifiedDocument()}"
            data-tooltip="Download">
            <span class="icon">
              <i class="mdi mdi-format-color-marker-cancel mdi-18px" />
            </span>
            <span>Download</span>
          </button>
        </div>
        <div style="align-self: flex-end;">
          <button class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Close</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import LazyImage from '@/components/LazyImage'
import { EmailService } from '@/services'

export default {
  name: 'QuoteEmailAttachmentViewerModal',
  components: {
    BaseModalEx,
    LazyImage
    // PsPdfViewer
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    quoteId: {
      type: String
    },
    // quoteAttachmentId: {
    //   type: String
    // },
    attachment: {
      type: Object
    },
    isIncoming: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.active || false,
      selectedDervied: null,
      internalAttachment: this.attachment,
      redaction: false,
      maxHeight: '600px'
    }
  },
  computed: {
    isPDF() {
      return this.internalAttachment.filename.toLowerCase().endsWith('pdf')
    },
    mainImageLink() {
      return EmailService.getQuoteEmailAttachmentUrl(this.quoteId, this.attachment.quoteEmailAttachmentId, this.isIncoming)
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    attachment(value) {
      this.internalAttachment = value
    }
  },
  created() {},
  mounted() {},
  methods: {
    downloadUrl(url, fileName) {
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async downloadModifiedDocument() {
      const response = await EmailService.downloadModifiedQuoteEmailAttachment(
        this.quoteId,
        this.internalAttachment.quoteEmailAttachmentId,
        this.internalAttachment.isIncoming
      )
      let fileName = this.attachment.filename
      this.downloadUrl('data:application/pdf;base64,' + response.data, fileName)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style>
</style>