<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="45"
    :min-height="85"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Email Template</span>
      </p>
    </header>
    <section class="modal-card-body">
      <vue-email-message-templates ref="emailMessageTemplates"
        :enable-save="enableSave"
        @save-button="setEnableSave"
      />
    </section>
    <footer class="modal-card-foot is-flex is-justify-content-flex-end">
      <div class="buttons">
        <button v-if="!enableSave"
          class="button is-success tooltip"
          data-tooltip="Save template"
          @click="add">
          <span class="icon">
            <i class="mdi mdi-plus mdi-20px" />
          </span>
          <span>New</span>
        </button>
        <button v-if="enableSave"
          class="button is-success tooltip"
          data-tooltip="Save template"
          @click="save">
          <span class="icon">
            <i class="mdi mdi-content-save mdi-20px" />
          </span>
          <span>Save</span>
        </button>
        <button v-if="enableSave"
          class="button tooltip"
          @click="cancel"
          data-tooltip="Cancel changes">
          <span class="icon">
            <i class="mdi mdi-content-save-off mdi-20px" />
          </span>
          <span>Cancel</span>
        </button>
        <button class="button tooltip"
          @click="close"
          data-tooltip="Close">
          <span class="icon">
            <i class="mdi mdi-close mdi-20px" />
          </span>
          <span>Close</span>
        </button>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import VueEmailMessageTemplates from './VueEmailMessageTemplates'

export default {
  name: 'VueEmailTemplateModal',
  components: { BaseModalEx, VueEmailMessageTemplates },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-message-text'
    }
  },
  data() {
    return {
      isActive: false,
      enableSave: false
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    edit() {},
    async send() {
      this.$emit('update:active', false)
      this.$emit('cancel')
    },
    add() {
      this.$refs.emailMessageTemplates.add()
      this.enableSave = true
    },
    close() {
      this.$emit('update:active', false)
      this.$emit('close')
      this.isActive = false
    },
    cancel() {
      this.$refs.emailMessageTemplates.cancel()
    },
    setEnableSave(value) {
      this.enableSave = value
    },
    async save() {
      await this.$refs.emailMessageTemplates.saveEmail()
      //console.log('save complete')
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
