<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem;">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Email</span>
      </p>
    </header>
    <section class="modal-card-body p-0 m-0"
      style="position:relative;">
      <div class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="p-0 m-0">
            <div class="columns is-multiline p-0 m-0">
              <div class="column is-9 p-3 m-0">
                From: <b>{{ email.sender }}</b>
              </div>
              <div class="column is-3 p-3 m-0  has-text-right">
                {{ $filters.formatDateTimeLocale(email.createdDate, $userInfo.locale) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="p-0 m-0">
            <div class="columns is-multiline p-0 m-0">
              <div class="column is-full p-3 m-0">
                To : <b>{{ email.recipients }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="email.cc && email.cc.length"
        class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="p-0 m-0">
            <div class="columns is-multiline p-0 m-0">
              <div class="column is-full p-3 m-0">
                Cc : <b>{{ email.cc }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="email.bcc && email.bcc.length"
        class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="p-0 m-0">
            <div class="columns is-multiline p-0 m-0">
              <div class="column is-full p-3 m-0">
                Bcc : <b>{{ email.bcc }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="box p-0 m-0">
            <div class="columns is-multiline p-0 m-0">
              <div class="column is-full p-3 m-0">
                <b>{{ email.subject }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns p-0 m-0">
        <div class="column p-0 m-0">
          <div class="box p-0 m-0">
            <div class="columns is-multiline p-0 m-0">
              <div class="column is-full p-3 m-0">
                <div v-html="getHtmlMessage()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;" />
        <div style="align-self: flex-end;">
          <button class="button tooltip"
            @click="close()"
            data-tooltip="Close">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Close</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'

export default {
  name: 'QuoteEmailViewerModal',
  components: {
    BaseModalEx
    // PsPdfViewer
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-email-open'
    },
    email: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      isActive: this.active || false,
      selectedDervied: null,
      internalAttachment: this.attachment,
      redaction: false,
      maxHeight: '600px'
    }
  },
  computed: {},
  watch: {
    email(value) {
      this.email = value
      console.log(this.email)
      console.log(this.isActive)
    },
    active(value) {
      this.isActive = value
      console.log(value)
    }
  },
  created() {},
  mounted() {},
  methods: {
    getHtmlMessage() {
      const html = (this.email.message ?? '').replace(/\n/g, '<br/>')

      return html
    },
    ucaseFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style>
</style>